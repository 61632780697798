import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = ({ children }) => {
  const [hotelId, setHotelId] = useState(null);
  const [outletId, setOutletId] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelectedItem = (item) => {    
    const updatedItems = selectedItems.filter(
      (selectedItem) => selectedItem.ItemId !== item.ItemId
    );

    if (updatedItems.length === selectedItems.length) {
      setSelectedItems([...selectedItems, item]);
    } else {
      setSelectedItems(updatedItems);
    }
  };

  return (
    <AppContext.Provider
      value={{
        selectedItems,
        toggleSelectedItem,
        hotelId,
        setHotelId,
        outletId,
        setOutletId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React from "react";

const Navbar = () => {
  return (
    <>
      <nav className="flex items-center flex-row bg-gradient-to-r from-blue-400 via-blue-300 to-blue-400 p-4 shadow-lg">
        <div className="flex items-center flex-col justify-between flex-wrap">
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <p className="text-center font-medium text-white uppercase lg:flex-grow">
              Generate Receipt
            </p>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";

const Menu = () => {
  const [cardData, setCardData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);

  const addButtonClicked = () => {
    setSelectedObject({
      id: 0,
      datetime: getCurrentDate(),
      amount: 0,
      name: "",
      account: "",
      ifsc: "",
      withdrawid: "",
      time: getFormattedTime(),
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveObject = () => {
    if (parseInt(selectedObject.id) === 0) {
      setCardData([
        ...cardData,
        {
          id: cardData.length + 1,
          datetime: selectedObject.datetime,
          amount: parseFloat(selectedObject.amount),
          name: selectedObject.name,
          account: selectedObject.account,
          ifsc: selectedObject.ifsc,
          withdrawid: selectedObject.withdrawid,
          time: selectedObject.time,
        },
      ]);
    } else {
      const updatedMenuData = cardData.map((obj) => {
        if (obj.id === parseInt(selectedObject.id)) {
          return {
            ...obj,
            datetime: selectedObject.datetime,
            amount: parseFloat(selectedObject.amount),
            name: selectedObject.name,
            account: selectedObject.account,
            ifsc: selectedObject.ifsc,
            withdrawid: selectedObject.withdrawid,
            time: selectedObject.time,
          };
        }
        return obj;
      });
      setCardData(updatedMenuData);
    }
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    setSelectedObject({
      ...selectedObject,
      [e.target.name]: e.target.value,
    });
  };

  const handleCardClick = (card) => {
    if (card) {
      console.log(card);
      setSelectedObject(card);
      setIsModalOpen(true);
    }
  };

  function getCurrentDate() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function getFormattedDateShort(dateStr) {
    const parts = dateStr.split("/");
    const day = parts[0];
    const monthIndex = parseInt(parts[1], 10) - 1;
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${day} ${monthNames[monthIndex]}`;
  }

  function getFormattedDate(dateStr) {
    const parts = dateStr.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${day}/${month}/${year}`;
  }

  function getFormattedTime() {
    const now = new Date();
    const options = { hour12: true, hour: "2-digit", minute: "2-digit" };
    return now.toLocaleTimeString("en-US", options);
  }

  return (
    <>
      <div className="container mx-auto px-2 py-8">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-normal py-2 px-5 mx-2 w-40 rounded"
          onClick={addButtonClicked}
        >
          Add Receipt
        </button>

        <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="max-w-md mx-2 bg-white shadow-lg rounded-lg overflow-hidden relative"
              onClick={() => handleCardClick(card)}
              style={{fontSize: "14px"}}
            >
              <div className="pl-6 pr-2 pt-1 pb-0 relative">
                <div className="flex justify-between items-center">
                  <div
                    className="absolute inset-0 bg-gradient-to-tr from-yellow-400 to-transparent rounded-tl-lg rounded-bl-lg mix-blend-multiply"
                    style={{
                      clipPath:
                        "polygon(65% 0%, 0% 0%, 0% 100%, 55% 100%, 57% 88%, 59% 73%, 61% 55%, 63% 31%)",
                    }}
                  ></div>
                  <div>
                    <span className="text-gray-500 font-semibold">
                      Withdraw Amount{" "}
                    </span>
                    <span className="text-red-500 font-semibold">
                      {card.amount}
                    </span>
                  </div>
                  <div className="px-2 py-2 w-28 text-center font-semibold bg-green-500 text-white rounded-full text-sm">
                    Success
                  </div>
                </div>
              </div>

              <div className="px-6 pb-4 pt-1">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2">
                    <table className="w-full">
                      <tbody>
                        <tr>
                          <td className="w-1/3">Name</td>
                          <td>{card.name}</td>
                        </tr>
                        <tr>
                          <td>Account</td>
                          <td>{card.account}</td>
                        </tr>
                        <tr>
                          <td>IFSC</td>
                          <td>{card.ifsc}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-span-1 flex justify-center items-center">
                    <p className="text-gray-700 text-base text-center" style={{fontSize: "14px"}}>
                      {card.time} <br />
                      {getFormattedDate(card.datetime)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-gray-200 px-6 py-2 text-left">
                Withdraw ID {card.withdrawid}
              </div>
            </div>
          ))}
        </div>
        {isModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none bg-gray-500 bg-opacity-50">
            <div className="relative w-full max-w-lg">
              <div className="bg-white rounded-lg shadow-lg relative flex flex-col">
                <div className="p-6 border-b border-gray-200">
                  <h2 className="text-lg font-semibold">Add Receipt</h2>
                </div>

                <div className="p-6">
                  <div className="mb-4">
                    <label
                      htmlFor="datetime"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date
                    </label>
                    <input
                      type="text"
                      id="datetime"
                      name="datetime"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.datetime}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="time"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Time
                    </label>
                    <input
                      type="text"
                      id="time"
                      name="time"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.time}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="amount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Amount
                    </label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.amount}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="account"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Account No
                    </label>
                    <input
                      type="text"
                      id="account"
                      name="account"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.account}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="ifsc"
                      className="block text-sm font-medium text-gray-700"
                    >
                      IFSC
                    </label>
                    <input
                      type="text"
                      id="ifsc"
                      name="ifsc"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.ifsc}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="withdrawid"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Withdraw Id
                    </label>
                    <input
                      type="text"
                      id="withdrawid"
                      name="withdrawid"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      value={selectedObject.withdrawid}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="flex justify-end p-4 bg-gray-100 border-t border-gray-200 rounded-b-lg">
                  <button
                    className="px-4 py-2 mr-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={handleSaveObject}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;

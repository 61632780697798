import './App.css';
import React from 'react';
import NavBar from './components/Navbar';
import Menu from './components/Menu';
import NotFound from './components/NotFound'

function App() {  

  return (
    <>
      <section>
        <NavBar/>
      </section>
      <section className="my-4">
        <Menu/>
      </section>
    </>
  );
}

export default App;
